html body {
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* Property styles */
.full-span {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Navbar */
.bg-dark {
  background-color: rgba(52,58,64,0.8) !important;
}

.logo {
  background-color: rgba(119, 22, 29, 0.8);
  border-bottom: 3px;
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
  padding: 0.2em 0.6em;
}

@media only screen and (max-width: 340px) {
  .logo{
    font-size: 1.3em;
  }
}

.navbar{
  color: rgba(0,0,0,0.7);
}

.nav-item {
  margin: 0.4em 0.2em;
}


.nav-item.active .nav-link{
  background-color: rgba(0,0,0,0.6);
  cursor: default;
}

.nav-link{
  border-bottom: 3px solid transparent;
  color: white !important;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 550;
  padding: 7px 7px !important;
}

.nav-link:hover{
  -webkit-animation: hoverSelect 220ms ease-out;
          animation: hoverSelect 220ms ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.nav-item.active .nav-link:hover{
  -webkit-animation: none !important;
          animation: none !important;
}

@-webkit-keyframes hoverSelect {
  from {}
  to {border-bottom: 3px solid white;}
}

@keyframes hoverSelect {
  from {}
  to {border-bottom: 3px solid white;}
}

/* Page style templates */
.page {
  background-color: rgba(255,255,255,0.2);
  background-size: cover;
  background-position: center;
  background-blend-mode: lighten;

  min-height: 100%;
  
  padding: 16vh 7vw 2vh 7vw;
}

.main-page {
  background-image: url('/img/office.jpg');
}

.services-page {
  background-image: url('/img/in-office.jpg');
}

.profile-page {
  background-image: url('/img/office-conf.jpg');
}

.contact-page {
  background-image: url('/img/office-ext.jpg');
}

.fade-in {
  -webkit-animation: fadein 500ms ease-in;
          animation: fadein 500ms ease-in;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.fade-out {
  -webkit-animation: fadeout 400ms ease-out;
          animation: fadeout 400ms ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes fadein {
  from {opacity: 0.3;}
  to {opacity: 1;}
}

@keyframes fadein {
  from {opacity: 0.3;}
  to {opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {opacity: 1;}
  to {opacity: 0.3;}
}

@keyframes fadeout {
  from {opacity: 1;}
  to {opacity: 0.3;}
}
.intro-area {
    text-align: center;
    padding: 30vh 10vw;
}

.text-main-page-intro {
    background-color: rgba(16, 17, 19, 0.6);
    border-radius: 1vh;
    color: white;
    font-size: 1.6em;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: italic;
    font-weight: 520;
    padding: 0.1em;
}
.portrait-frame {
    background-color: rgba(255,255,255,0.5);   
    border-radius: 50%;
    height: 14em;
    position: relative;
    width: 14em;
    z-index: 100;
}

.portrait {
    background-color: white;
    border-radius: 50%;
    height: 13em;    
    margin: auto;  
    position: relative;
    -webkit-transform: translateY(0.5em);
            transform: translateY(0.5em);
    width: 13em;
    z-index: 110;
}

.profile-section {
    max-width: 14em;
    margin: 1em 2em 0 2em;
}

.profile-chevron-wrapper {
    position: relative;
    top: -6em;
    z-index: 0;
}

.profile-chevron-wrapper::before {
    background-color: rgba(216,65,4,0.7);
    content: '';
    display: block;
    height: 6em;
}

.profile-chevron-wrapper .triangle {
    border-color: transparent;
    border-style: solid;
    border-top-color: rgba(216,65,4,0.7);
    border-width: 2em 7em 0 7em;
}

.profile-chevron-wrapper .rectangle {
    background-color: rgba(216,65,4,0.7);
    width: 100%;
    height: 5em;
}

.profile-chevron-wrapper .bar {
    background-color: rgba(216,65,4,0.7);
    margin-top: 5px;
    height: 1.5em;
}

.profile-text {
    text-align: center;
    margin: 0;
}

.profile-text.name {
    font-size: 1.5em;
    font-weight: 600;
}

.profile-text.license {
    font-style: italic;
    white-space: pre-wrap;
}
.jumbotron {
    background-color: rgba(255, 255, 255, 0.85);
    margin: 0;
    padding: 1.7em;
}

.service-text {
    border-bottom: 2px solid darkslategray;
    color: darkslategray;
    font-size: 1.3em;
    padding-bottom: 0.3em;
}
.contact-info {
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 1vh;
    padding: 1.5em;
}
