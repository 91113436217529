.intro-area {
    text-align: center;
    padding: 30vh 10vw;
}

.text-main-page-intro {
    background-color: rgba(16, 17, 19, 0.6);
    border-radius: 1vh;
    color: white;
    font-size: 1.6em;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: italic;
    font-weight: 520;
    padding: 0.1em;
}