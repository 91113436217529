.jumbotron {
    background-color: rgba(255, 255, 255, 0.85);
    margin: 0;
    padding: 1.7em;
}

.service-text {
    border-bottom: 2px solid darkslategray;
    color: darkslategray;
    font-size: 1.3em;
    padding-bottom: 0.3em;
}