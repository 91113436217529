.portrait-frame {
    background-color: rgba(255,255,255,0.5);   
    border-radius: 50%;
    height: 14em;
    position: relative;
    width: 14em;
    z-index: 100;
}

.portrait {
    background-color: white;
    border-radius: 50%;
    height: 13em;    
    margin: auto;  
    position: relative;
    transform: translateY(0.5em);
    width: 13em;
    z-index: 110;
}

.profile-section {
    max-width: 14em;
    margin: 1em 2em 0 2em;
}

.profile-chevron-wrapper {
    position: relative;
    top: -6em;
    z-index: 0;
}

.profile-chevron-wrapper::before {
    background-color: rgba(216,65,4,0.7);
    content: '';
    display: block;
    height: 6em;
}

.profile-chevron-wrapper .triangle {
    border-color: transparent;
    border-style: solid;
    border-top-color: rgba(216,65,4,0.7);
    border-width: 2em 7em 0 7em;
}

.profile-chevron-wrapper .rectangle {
    background-color: rgba(216,65,4,0.7);
    width: 100%;
    height: 5em;
}

.profile-chevron-wrapper .bar {
    background-color: rgba(216,65,4,0.7);
    margin-top: 5px;
    height: 1.5em;
}

.profile-text {
    text-align: center;
    margin: 0;
}

.profile-text.name {
    font-size: 1.5em;
    font-weight: 600;
}

.profile-text.license {
    font-style: italic;
    white-space: pre-wrap;
}