html body {
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* Property styles */
.full-span {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Navbar */
.bg-dark {
  background-color: rgba(52,58,64,0.8) !important;
}

.logo {
  background-color: rgba(119, 22, 29, 0.8);
  border-bottom: 3px;
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
  padding: 0.2em 0.6em;
}

@media only screen and (max-width: 340px) {
  .logo{
    font-size: 1.3em;
  }
}

.navbar{
  color: rgba(0,0,0,0.7);
}

.nav-item {
  margin: 0.4em 0.2em;
}


.nav-item.active .nav-link{
  background-color: rgba(0,0,0,0.6);
  cursor: default;
}

.nav-link{
  border-bottom: 3px solid transparent;
  color: white !important;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 550;
  padding: 7px 7px !important;
}

.nav-link:hover{
  animation: hoverSelect 220ms ease-out;
  animation-fill-mode: forwards;
}

.nav-item.active .nav-link:hover{
  animation: none !important;
}

@keyframes hoverSelect {
  from {}
  to {border-bottom: 3px solid white;}
}

/* Page style templates */
.page {
  background-color: rgba(255,255,255,0.2);
  background-size: cover;
  background-position: center;
  background-blend-mode: lighten;

  min-height: 100%;
  
  padding: 16vh 7vw 2vh 7vw;
}

.main-page {
  background-image: url('/img/office.jpg');
}

.services-page {
  background-image: url('/img/in-office.jpg');
}

.profile-page {
  background-image: url('/img/office-conf.jpg');
}

.contact-page {
  background-image: url('/img/office-ext.jpg');
}

.fade-in {
  animation: fadein 500ms ease-in;
  animation-fill-mode: forwards;
}

.fade-out {
  animation: fadeout 400ms ease-out;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  from {opacity: 0.3;}
  to {opacity: 1;}
}

@keyframes fadeout {
  from {opacity: 1;}
  to {opacity: 0.3;}
}